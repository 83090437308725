@font-face {
  font-family: 'Greycliff CF';
  src: url('../assets/font/greycliff-cf-light-webfont.woff2') format('woff2'),
    url('../assets/font/greycliff-cf-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Greycliff CF';
  src: url('../assets/font/greycliff-cf-heavy-webfont.woff2') format('woff2'),
    url('../assets/font/greycliff-cf-heavy-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Greycliff CF';
  src: url('../assets/font/greycliff-cf-medium-webfont.woff2') format('woff2'),
    url('../assets/font/greycliff-cf-medium-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Greycliff CF';
  src: url('../assets/font/greycliff-cf-bold-webfont.woff2') format('woff2'),
    url('../assets/font/greycliff-cf-bold-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
