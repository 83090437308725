@import 'style/_fonts.scss';
@import 'style/_theme.scss';

// No permite selección de objetos visuales con el puntero
@mixin not-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Greycliff CF,
    "Helvetica Neue",
    sans-serif;
}

h1,
mat-label,
a,
button,
th,
img,
.not-selectable,
.mat-step-text-label,
.mat-step-icon-content,
mat-checkbox,
mat-toolbar,
mat-panel-title,
mat-form-field,
mat-paginator,
mat-checkbox,
mat-icon {
  @include not-selectable;
}
